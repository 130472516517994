import React from 'react';
import QRCode from 'qrcode.react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent, 
  Divider,
  Grid,
  TextField,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

function QrCode (props){
  const { className, ...rest } = props;
  const classes = useStyles();
  const STORE_URL1 = process.env.REACT_APP_STORE_URL;
    let storeName = localStorage.getItem('store');
    
  // let storeName ='lobani'
const STORE_URL = `${STORE_URL1}/${storeName}`;
const LOBANI_URL = `https://${storeName}.wajenmenu.com/${storeName}`;

  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
   
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  return (
    <Card
    {...rest}
    className={clsx(classes.root, className)}
  dir='rtl'>

<CardHeader
          title="رمز الإستجابة السريع"
          subheader="قم تحميل رمز الإستجابة السريع بسهولة"
        />
        <Divider />
        <CardContent dir='rtl'>
          <Grid
            container
            spacing={3}
          >

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                type="text"
                placeholder="Enter QR Code"
                value={storeName == 'lobani' || storeName =='heisenberg' 
                || storeName =='tulua' || storeName =='range'|| storeName =='secret'
                || storeName =='arksh' || storeName =='miso' || storeName =='bigboom'
                || storeName =='GBR' || storeName =='eensukkar' ? LOBANI_URL : STORE_URL}
                variant="outlined"
              />
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12} >
                  <a href={storeName == 'lobani' || storeName =='heisenberg' 
                  || storeName =='tulua' || storeName =='range' || storeName =='secret' 
                  || storeName =='arksh' || storeName =='miso' || storeName =='bigboom'
                  || storeName =='GBR'|| storeName =='eensukkar' ? LOBANI_URL : STORE_URL}
        target="_blank"
        rel="noreferrer">
                <Button
                 variant='contained'
                color='success' 
                className='my-2 px-2'
                >
                  معاينة مباشرة
                </Button>
                </a>
            </Grid>

        <Grid
              item
              md={6}
              xs={12}
            >
      <Button variant='contained' color='success' onClick={downloadQRCode}>تحميل الرمز</Button>
      </Grid>
          
      <Grid
              item
              md={6}
              xs={12}
            >
      <QRCode
        id="qrCodeEl"
        size={150}
        value={storeName == 'lobani' || storeName =='heisenberg'  
        || storeName =='tulua' || storeName =='range' || storeName =='secret'
        || storeName =='arksh' || storeName =='miso' || storeName =='bigboom'
        || storeName =='GBR'|| storeName =='eensukkar' ? LOBANI_URL : STORE_URL}
      />
     </Grid>
      </Grid>
     </CardContent>
    </Card>
  );
}

export default QrCode;
