import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { ShopContext } from 'context/shop-context';
import { AiFillAppstore } from "react-icons/ai";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const {userLoggedOut} = useContext(ShopContext);

  const classes = useStyles();

  const [notifications] = useState([]);

  const STORE_URL1 = process.env.REACT_APP_STORE_URL;
  const storeName = localStorage.getItem('store');

// const LOBANI_URL = "https://lobani.wajenmenu.com/lobani";

  // let storeName ='lobani'
  const STORE_URL = `${STORE_URL1}/${storeName}`;
  const LOBANI_URL = `https://${storeName}.wajenmenu.com/${storeName}`;
  
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            src="/images/logos/logo01.svg"
          width="100%"/>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
       <a href={storeName == 'lobani' || storeName =='heisenberg'
       || storeName =='tulua'|| storeName =='range' || storeName =='secret'
       || storeName =='arksh' || storeName =='miso' || storeName =='bigboom' 
       || storeName =='GBR'|| storeName =='eensukkar' ? LOBANI_URL : STORE_URL}
        target="_blank"
        rel="noreferrer">
          <IconButton color="inherit" >
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <AiFillAppstore color='#fff' size='26px'/>
            </Badge>
          </IconButton>
          </a>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={userLoggedOut}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
