import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Loader from "react-js-loader"; 
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { ShopContext } from 'context/shop-context';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditItem = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const {id} = useParams(); 
    const [img, setImg] = useState('');

    const BASE_URL = process.env.REACT_APP_API_URL;
    const PRODUCT_URL = `${BASE_URL}/products/${id}`; 
    const EDIT_URL = `${BASE_URL}/product/${id}`; 
    
    const [loader, setLoader] = useState(false);

    const {cate} = useContext(ShopContext) 
    const [selectedFile, setSelectedFile] = useState(null);
    const history = useHistory();

    useEffect(() => {
      loadList();
    }, []);

    const loadList = async () => {
      const res = await axios.get(EDIT_URL);
      setData(res.data);
      setImg(res.data.image)
      console.log(res.data.image);
    };

    const [data, setData] = useState({
      name : "",
      ar_name : "",
      price : 0,
      rate : 0.0,
      description : "",
      ar_description : "",
      // category : "",
      category_id : 0,
      calories : 0,
    });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

    const handleSubmission = async (e) => {
      setLoader(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("image", selectedFile || img );
      formData.append("name", data.name);
      formData.append("ar_name", data.ar_name);
      formData.append("price", data.price);
      formData.append("rate", data.rate);
      formData.append("description", data.description);
      formData.append("ar_description", data.ar_description);
      formData.append("category_id", data.category_id);
      
      // formData.append("ar_category", data.ar_category);
      formData.append("calories", data.calories);
      await fetch(PRODUCT_URL, {
        method: "POST",
        body: formData,
        headers:{
          'Authorization': ` Bearer ${passedToken}` 
        }
      })
      .then((res)=>{
        setLoader(false);
        console.log(res);
        if(!res.status){
          toast.error('فشل الاتصال بالخادم الرجاء المحاولة مرة أخرى');
        }

        else if(res.status == 500){
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        }
        else if(res.status == 404){
          setLoader(false);
          toast.error('تأكد من الاتصال و المحاولة مرة أخرى');
        }
        else if(res.status == 200){
          setLoader(false);
            history.push('/users', toast.success('تم تعديل المنتج'))
        }
        else{
          setLoader(false);
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        } 
        loadList();
      })
      .catch(()=>{
        toast.error('حدث خطأ الرجاء المحاولة مرة أخرى')
        setLoader(false);
      });
    };

    function handle(e){
      const newdata={...data}
      newdata[e.target.id] = e.target.value
      setData(newdata)
      console.log(newdata)
    }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
      <form onSubmit={handleSubmission}>
        <CardHeader
        title="تعديل المنتج"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="اسم المنتج باللغة الانجليزية"
                label="Product Name"
                margin="dense"
                name="name"
                id="name"
                onChange={(e)=> handle(e)} 
                value={data.name}
                required
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="اسم المنتج باللغة العربية"
                label="اسم المنتج"
                margin="dense"
                name="ar_name"
                id="ar_name"
                onChange={(e)=> handle(e)} 
                value={data.ar_name}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
    
            
              <TextField
                fullWidth
                label="سعر المنتج"
                helperText="أدخل سعر المنتج"
                margin="dense"
                name="price"
                id="price"
                onChange={(e)=> handle(e)} 
                value={data.price}
                type="number"
               required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="السعرات الحرارية"
                helperText="أدخل السعرات الحرارية"
                margin="dense"
                id="calories"
                name="calories"
                onChange={(e)=> handle(e)} 
                value={data.calories}
                type="number"
               required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Product Description"
                helperText="أدخل وصف المنتج بالانجليزي"
                margin="dense"
                id="description" 
                name="description"
                onChange={(e)=> handle(e)} 
                value={data.description}
                type="text"
               
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="وصف المنتج"
                helperText="أدخل وصف المنتج بالعربي"
                margin="dense"
                id="ar_description" 
                name="ar_description"
                onChange={(e)=> handle(e)} 
                value={data.ar_description}
                type="text"
               
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >

              <TextField
                fullWidth
                label="Select Category"
                helperText="أختر الصنف بالانجليزي"
                margin="dense"
                id="category"
                name="category"
                onChange={(e)=> handle(e)} 
                value={data.category}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {cate.map(option => (
                  <option
                    key={option.category}
                    value={option.category}
                  >
                    {option.category}
                  </option>
                ))}
              </TextField>
            </Grid> */}
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="أختر الصنف"
                helperText="أختر الصنف بالعربي"
                margin="dense"
                id="category_id"
                name="category_id"
                onChange={(e)=> handle(e)} value={data.category_id}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {cate.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.ar_category}
                  </option>
                ))}
              </TextField>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="التقييم"
                helperText="أختر قيمة بين 0.0 الى 5.0"
                margin="dense"
                id="rate"
                type="number"
                name="rate"
                onChange={(e)=> handle(e)} value={data.rate}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
               <label for="inputImage">أضف صورة المنتج </label>
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                 type="file"
                  name="file"
                  className="form-control" 
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                variant="outlined"
              />

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>

<button className='btn btn-primary px-3  m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <> تحديث البيانات</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

EditItem.propTypes = {
  className: PropTypes.string
};

export default EditItem;
