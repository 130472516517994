import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import Loader from "react-js-loader"; 
import {
  Card,
  CardHeader,
  CardContent, 
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { ShopContext } from 'context/shop-context';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddItem = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const PRODUCT_URL = `${BASE_URL}/products`; 

  const [loader, setLoader] = useState(false);

  const {cate} = useContext(ShopContext) ;
  const [selectedFile, setSelectedFile] = useState();
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  console.log(showError);
  const [showArError, setShowArError] = useState(false);

  const [data, setData] = useState({
      name : "",
      ar_name : "",
      price : null,
      rate : null,
      description : "",
      ar_description : "",
      // category_id : 0,
      // ar_category : "",
      calories :null,
  });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

      const handleSubmission = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData();
        formData.append("image", selectedFile);
        formData.append("name", data.name);
        formData.append("ar_name", data.ar_name);
        formData.append("price", data.price);
        formData.append("rate", data.rate);
        formData.append("description", data.description);
        formData.append("ar_description", data.ar_description);
        formData.append("category_id", data.ar_category);
        // formData.append("ar_category", data.ar_category);
        formData.append("calories", data.calories);
        await fetch (PRODUCT_URL, {
          method: "POST",
          body: formData,
          headers:{
            'Authorization': ` Bearer ${passedToken}`
          }
        })
        .then((res)=>{ 
       
            if(!res.status){
              toast.error('فشل الاتصال بالخادم')
              setLoader(false);
            }
       
            else if(res.status == 201){
              toast.success('تم إضافة المنتج');
              setLoader(false);
                history.push('/users');
            }

            else if(res.status == 419){
                toast.error('حدث خطأ حاول مرة أخرى');
              setLoader(false);
            }

            else if(res.status == 500){
              toast.error('حدث خطأ  حاول مرة أخرى');
              setLoader(false);
            }
            
            else if(res.status == 404){
              toast.error('تأكد من الاتصال بالانترنت و حاول مرة أخرى');
              setLoader(false);
            }
            else if(!data.category || data.category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
              setShowError(true);
           
            }
            else if(!data.ar_category || data.ar_category.trim() === ''){
              toast.error('تاكد من إضافة الحقول المطلوبة');
              setLoader(false);
              setShowArError(true);
              setShowError(false);
            }
            else{
              toast.error('لا يمكن الاستجابة لطلبك حاول مرة أخرى')
              setLoader(false);
              setShowArError(false);
              setShowError(false);
            }
        })
        .catch(()=>{
          toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
          setLoader(false);
          setShowArError(false);
          setShowError(false);
        });
      }; 

      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
      }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    dir='rtl'>
 
      <form onSubmit={handleSubmission}>
        <CardHeader
          subheader="قم بملء جميع الحقول المطلوبة (*)"
          title="إضافة منتج جديد"
        />
        <Divider />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="اسم المنتج باللغة الانجليزية"
                label="Product Name"
                margin="dense"
                name="name"
                id="name"
                onChange={(e)=> handle(e)} 
                value={data.name}
                required
                variant="outlined"
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="اسم المنتج باللغة العربية"
                label="اسم المنتج"
                margin="dense"
                name="ar_name"
                id="ar_name"
                onChange={(e)=> handle(e)} 
                value={data.ar_name}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
            
              <TextField
                fullWidth
                label="سعر المنتج"
                helperText="أدخل سعر المنتج"
                margin="dense"
                name="price"
                id="price"
                onChange={(e)=> handle(e)} 
                value={data.price}
                type="number"
               required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="السعرات الحرارية"
                helperText="أدخل السعرات الحرارية"
                margin="dense"
                id="calories"
                name="calories"
                onChange={(e)=> handle(e)} 
                value={data.calories}
                type="number"
             
                variant="outlined"
                required
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Product Description"
                helperText="أدخل وصف المنتج بالانجليزي"
                margin="dense"
                id="description" 
                name="description"
                onChange={(e)=> handle(e)} 
                value={data.description}
                type="text"
               
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="وصف المنتج"
                helperText="أدخل وصف المنتج بالعربي"
                margin="dense"
                id="ar_description" 
                name="ar_description"
                onChange={(e)=> handle(e)} 
                value={data.ar_description}
                type="text"
               
                variant="outlined"
              />
            </Grid>

            {/* <Grid
              item
              md={6}
              xs={12} >
              <TextField
                fullWidth
                // label="Select Category"
                helperText="الصنف بالانجليزي"
                margin="dense"
                id="category"
                name="category"
                onChange={(e)=> handle(e)} 
                value={data.category}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
              
                <option value={null}>Select Category</option>
                {cate.map(option => (
                  <option
                    key={option.category}
                    value={option.category}
                  >
                    {option.category}
                  </option>
                ))}
              </TextField>
              {showError && <span className='text-danger'>This field is required</span>}
            </Grid> */}
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // label="أختر الصنف"
                helperText="الصنف بالعربي"
                margin="dense"
                id="ar_category"
                name="ar_category"
                onChange={(e)=> handle(e)} value={data.ar_category}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              >
                   <option value={null}>أختر الصنف</option>
                {cate.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.ar_category}
                  </option>
                ))}
              </TextField>
              {showArError && <span className='text-danger'>هذا الحقل اجباري</span>}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="التقييم"
                helperText="أختر قيمة بين 0.0 الى 5.0"
                margin="dense"
                id="rate"
                type="number"
                name="rate"
                onChange={(e)=> handle(e)} value={data.rate}
                variant="outlined"
                required
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
               <label for="inputImage">أضف صورة المنتج </label>
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                 type="file"
                  name="file"
                  className="form-control" 
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                variant="outlined"
                required
              />

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
 

<button className='btn btn-primary px-3  m-4' >
          {loader ?
          <div className={"item"}>
        <Loader type="bubble-loop" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={60} />
      </div> : 
        <>اضافة المنتج</>
        }
        </button>

        </CardActions>
      </form>
    </Card>
  );
};

AddItem.propTypes = {
  className: PropTypes.string
};

export default AddItem;
