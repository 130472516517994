import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  TextField
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 980
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

 function UsersTable(props) {
  
  const localhost_url = process.env.REACT_APP_IMAGE_URL;
  const BASE_URL = process.env.REACT_APP_API_URL;
  const PRODUCT_URL = `${BASE_URL}/products`;

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filteredPageCount, setFilteredpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [msg , setMsg] = useState(false);
  const [isLoading , setIsLoading] = useState(true);
  const [leng , setLeng] = useState(0);

  const [filterCurrentPage, setFilterCurrentPage] = useState(0);

  
  const [selectedCategory, setSelectedCategory] = useState();


  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData(currentPage);
  },[]);

  useEffect(() => {
    fetechFilteredData(filterCurrentPage);
    
  }, [selectedCategory]);
  
 
  const fetchData =  async (pageNumber) => {
    await axios.get(`${PRODUCT_URL}?perPage=10&page=${pageNumber}`,  { 
        headers:{
          'Authorization': ` Bearer ${passedToken}`
        }
      })
      .then( (response) => {
        setData(response.data.data);
        setPageCount(response.data.last_page);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


const fetechFilteredData =   async (pageNumber) => {
   await axios.get(`${PRODUCT_URL}/category/${selectedCategory}?perPage=10&page=${pageNumber}`,  { 
    headers:{
      'Authorization': ` Bearer ${passedToken}`
    }
  })
  .then(  (response) => {
    setFilteredData(response.data.data);
    setFilteredpageCount(response.data.last_page);
    setLeng(response.data.data.length);
    setIsLoading(false);
  })
  .catch((error) => {
    console.log(error);
  });
};

  const handlePageClick = (data) => {
    const selectedPage = ((data.selected) + 1);
    setCurrentPage(selectedPage);
    fetchData(selectedPage);
    
  };
  
const hnadleFilteredPageClick = (data) =>{
  const selectedPage = ((data.selected) + 1);
  setFilterCurrentPage(selectedPage);
  fetechFilteredData(selectedPage);
}

  const { className, ...rest } = props;
  const {deleteItem, cate, setItem} = useContext(ShopContext);

  const classes = useStyles();

  useEffect(()=>{
    setItem(data);
   
  },[data])


function handleCategoryChange(event) {
  setSelectedCategory(event.target.value);
}

function getFilteredList() {
  if (!selectedCategory) {
    setMsg(false);
    return data;
   
  
  }

  setFilterCurrentPage(0);

  return filteredData;
}
var filteredList = useMemo(getFilteredList, [selectedCategory, data, filteredData]);
console.log('selectedCategory', selectedCategory);
console.log('filteredData', filteredData);
useEffect(()=>{
if(leng == 0 ){
  setMsg(true);
}
else{
  setMsg(false);
}
}, [leng])

console.log('filteredDataLength', leng);
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>

<div className='category-filter m-2 '>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                margin="dense"
                id="ar_category"
                name="ar_category"
                onChange={handleCategoryChange}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option  value={setItem}>جميع الأصناف</option>
                {cate.slice(0).map(option => (
                  <option
                    key={option.id}
                    value={option.ar_category}
                  >
                    {option.ar_category}
                  </option>
                ))}
              </TextField>
            </Grid>
            </div>

        
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead >
                <TableRow >
             
                  <TableCell className='d-flex align-items-start'>اسم المنتج</TableCell>
                  <TableCell align='right'>الصنف</TableCell>
                  <TableCell align='right'>السعر</TableCell>
                  <TableCell align='right'>تعديل</TableCell>
                  <TableCell align='right'>حذف</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? <h6 align='left' className='text-secondary content-align-end m-3'>جاري تحميل المنتجات ...</h6>:
              <>
              {msg ? <h6 align='left' className='text-secondary content-align-end m-3'>لا توجد منتجات لهذا الصنف</h6> :
              <TableBody>
                {filteredList.slice(0).map(post => (
               
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={post.id}
                  >     
       
                    <TableCell >
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={localhost_url + post.image}
                        >
                          {getInitials(post.name)}
                        </Avatar>
                        <Typography variant="body1" className='ar-name mx-2'>{post.ar_name}</Typography>
                      </div>
                    </TableCell> 
                    <TableCell align='right'>{post.category_ar_name}</TableCell>
            
                    <TableCell align='right'>{post.price}</TableCell>
                    <TableCell align='right'>
                    <Link to={`/edit-item/${post.id}`} className='btn btn-primary btn-sm' ><FaEdit size="20px" /></Link>
                    </TableCell>
              
                    <TableCell align='right'>
                    <button className='btn btn-danger btn-sm' onClick={(e)=> deleteItem(e, post.id)} ><RiDeleteBack2Fill size="20px" /></button>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
              }
              </>
 }
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions} dir='rtl'>
        <div className='pagination' dir='rtl'>
          <ReactPaginate
            previousLabel={"السابق"}
            nextLabel={"التالي"}
            breakLabel={"..."}
            pageCount={selectedCategory ? filteredPageCount : pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={selectedCategory ? hnadleFilteredPageClick : handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
      </CardActions>
    </Card>

  );
}

export default UsersTable;
