import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import Loader from "react-js-loader";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const storeName = localStorage.getItem('store');
  const BASE_URL = process.env.REACT_APP_API_URL;
  const PRODUCT_URL1 = `${BASE_URL}/profiles/${storeName}`;


  const PRODUCT_URL = `${BASE_URL}/profiles/${storeName}`;

  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [coverImage, setCoverImage] = useState();
  const [coverImageTwo, setCoverImageTwo] = useState();
  const [coverImageThree, setCoverImageThree] = useState();
  const [loadimage, setLoadImage] = useState([]);
  const [logo, setLogo] = useState('');
  const [cover, setCover] = useState('');
  const [coverTwo, setCoverTwo] = useState('');
  const [coverThree, setCoverThree] = useState('');
  console.log(loadimage);

  const [data, setData] = useState({
    name: "",
    address: "",
    product_type: 0,
    phoneNumber: "",
    facebook_url: "",
    snapchat_url: "",
    tiktok_url: "",
    insta_url: "",
    twitter_url: "",

    saturdayOpen: "",
    sundayOpen: "",
    mondayOpen: "",
    tuesdayOpen: "",
    wednesdayOpen: "",
    thursdayOpen: "",
    fridayOpen: "",


    saturdayClose: "",
    sundayClose: "",
    mondayClose: "",
    tuesdayClose: "",
    wednesdayClose: "",
    thursdayClose: "",
    fridayClose: "",
  });

  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    const result = await axios.get(PRODUCT_URL1);

    setData(result.data);
    setLogo(result.data.logo);
    setCover(result.data.cover);
    setCoverTwo(result.data.cover_two);
    setCoverThree(result.data.cover_three);

    setLoadImage(result.data.reverse());

  };

  window.localStorage.setItem("storeID", data.id);

  const handleSubmission = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("logo", selectedFile || logo);
    formData.append("cover", coverImage || cover);
    formData.append("cover_two", coverImageTwo || coverTwo);
    formData.append("cover_three", coverImageThree || coverThree);
    formData.append("name", data.name);
    formData.append("address", data.address);
    formData.append("product_type", data.product_type);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("facebook_url", data.facebook_url);
    formData.append("snapchat_url", data.snapchat_url);
    formData.append("tiktok_url", data.tiktok_url);
    formData.append("insta_url", data.insta_url);
    formData.append("twitter_url", data.twitter_url);

    formData.append("saturdayOpen", data.saturdayOpen);
    formData.append("sundayOpen", data.sundayOpen);
    formData.append("mondayOpen", data.mondayOpen);
    formData.append("tuesdayOpen", data.tuesdayOpen);
    formData.append("wednesdayOpen", data.wednesdayOpen);
    formData.append("thursdayOpen", data.thursdayOpen);
    formData.append("fridayOpen", data.fridayOpen);

    formData.append("saturdayClose", data.saturdayClose);
    formData.append("sundayClose", data.sundayClose);
    formData.append("mondayClose", data.mondayClose);
    formData.append("tuesdayClose", data.tuesdayClose);
    formData.append("wednesdayClose", data.wednesdayClose);
    formData.append("thursdayClose", data.thursdayClose);
    formData.append("fridayClose", data.fridayClose);
    await fetch(PRODUCT_URL, {
      method: "POST",
      body: formData,
      headers: {
        'Authorization': ` Bearer ${passedToken}`
      }

    })
      .then((res) => {

        if (!res.status) {
          toast.error('لا يمكن الاتصال بالخادم في الوقت الحالي')
          setLoader(false);
        }
        else if (res.status == 200) {
          setLoader(false);
          history.replace('/account',  toast.success('تم تعديل البيانات'))
        }
        else if (res.status == 500) {
          toast.error('حدث خطأ حاول مرة أخرى')
          setLoader(false);
        }
        else if (res.status == 404) {
          toast.error('الرجاء التاكد من الاتصال والمحاولة مرة أخرى');
          setLoader(false);
        }
        else {
          toast.error('حدث خطأ حاول مرة أخرى');
          setLoader(false);
        }
        loadList();
      })
      .catch(() => {
        toast.error('حدث خطأ حاول مرة أخرى');
        setLoader(false);
      });
  };

  function handle(e) {
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata);
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      dir='rtl'>

      <form onSubmit={handleSubmission}>
        <CardHeader
          subheader="قم بملء جميع الحقول المطلوبة (*)"
          title="الإعدادات العامة"
        />
        <Divider />

        <CardContent>
          <div className='form-msg'>
          </div>
          <Grid
            container
            spacing={3}
          >

            <Grid
              item
              md={6}
              xs={12}>
              <TextField
                fullWidth
                label="اسم المتجر"
                margin="dense"
                name="name"
                id="name"
                onChange={(e) => handle(e)}
                value={data.name}
                required
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth

                label="العنوان"
                margin="dense"
                name="address"
                id="address"
                onChange={(e) => handle(e)}
                value={data.address}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >


              <TextField
                fullWidth
                label="رقم الهاتف"
                margin="dense"
                name="phoneNumber"
                id="phoneNumber"
                onChange={(e) => handle(e)}
                value={data.phoneNumber}
                type="number"
                
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12} >
              <TextField
                fullWidth
                label="نوع النشاط"
                margin="dense"
                id="product_type"
                name="product_type"
                onChange={(e) => handle(e)}
                value={data.product_type}
                required
                select
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <option value={1} >مقهى</option>
                <option value={0}>كافيه</option>
                <option value={2}>مطعم</option>
              </TextField>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="رابط فيس بوك"
                margin="dense"
                id="facebook_url"
                name="facebook_url"
                onChange={(e) => handle(e)}
                value={data.facebook_url}
                type="text"

                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="رابط سناب شات"
                margin="dense"
                id="snapchat_url"
                name="snapchat_url"
                onChange={(e) => handle(e)}
                value={data.snapchat_url}
                type="text"

                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="رابط تيك توك"
                margin="dense"
                id="tiktok_url"
                name="tiktok_url"
                onChange={(e) => handle(e)}
                value={data.tiktok_url}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label=" رابط الانستقرام"
                margin="dense"
                id="insta_url"
                name="insta_url"
                onChange={(e) => handle(e)}
                value={data.insta_url}
                type="text"
                variant="outlined"
              />
            </Grid>
            {/* 
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label=" رابط تويتر"
              margin="dense"
              id="twitter_url"
              name="twitter_url"
              onChange={(e)=> handle(e)} 
              value={data.twitter_url}
              type="text"
              variant="outlined"
            />
          </Grid> */}

            <Divider />

            <Grid
              item
              md={6}
              xs={12}
            >
              <label for="inputImage">شعار المطعم</label>
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                type="file"
                name="file"
                className="form-control"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                variant="outlined"

              />

            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label for="inputImage"> صورة العرض الأولى  </label>
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                type="file"
                name="file"
                className="form-control"
                onChange={(e) => setCoverImage(e.target.files[0])}
                variant="outlined" />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label for="inputImage">صورة العرض الثانية</label>
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                type="file"
                name="file"
                className="form-control"
                onChange={(e) => setCoverImageTwo(e.target.files[0])}
                variant="outlined" />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label for="inputImage">صورة العرض الثالثة</label>
              <TextField
                fullWidth
                margin="dense"
                helperText="نوع الصورة المرفقة (jpg,png,jpeg,gif,svg)"
                id="inputImage"
                type="file"
                name="file"
                className="form-control"
                onChange={(e) => setCoverImageThree(e.target.files[0])}
                variant="outlined" />
            </Grid>

            <Divider />
            <Divider />
            <Grid
              item
              md={6}
              xs={12}
            >
              <label>السبت</label>
              <TextField
                fullWidth
                label=" من"
                margin="dense"
                id="saturdayOpen"
                name="saturdayOpen"
                onChange={(e) => handle(e)}
                value={data.saturdayOpen}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>السبت</label>
              <TextField
                fullWidth
                label="الى"
                margin="dense"
                id="saturdayClose"
                name="saturdayClose"
                onChange={(e) => handle(e)}
                value={data.saturdayClose}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الأحد</label>
              <TextField
                fullWidth
                label=" من"
                margin="dense"
                id="sundayOpen"
                name="sundayOpen"
                onChange={(e) => handle(e)}
                value={data.sundayOpen}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الأحد</label>
              <TextField
                fullWidth
                label="الى"
                margin="dense"
                id="sundayClose"
                name="sundayClose"
                onChange={(e) => handle(e)}
                value={data.sundayClose}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الاثنين</label>
              <TextField
                fullWidth
                label=" من"
                margin="dense"
                id="mondayOpen"
                name="mondayOpen"
                onChange={(e) => handle(e)}
                value={data.mondayOpen}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الاثنين</label>
              <TextField
                fullWidth
                label="الى"
                margin="dense"
                id="mondayClose"
                name="mondayClose"
                onChange={(e) => handle(e)}
                value={data.mondayClose}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الثلاثاء</label>
              <TextField
                fullWidth
                label=" من"
                margin="dense"
                id="tuesdayOpen"
                name="tuesdayOpen"
                onChange={(e) => handle(e)}
                value={data.tuesdayOpen}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الثلاثاء</label>
              <TextField
                fullWidth
                label="الى"
                margin="dense"
                id="tuesdayClose"
                name="tuesdayClose"
                onChange={(e) => handle(e)}
                value={data.tuesdayClose}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الاربعاء</label>
              <TextField
                fullWidth
                label=" من"
                margin="dense"
                id="wednesdayOpen"
                name="wednesdayOpen"
                onChange={(e) => handle(e)}
                value={data.wednesdayOpen}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الاربعاء</label>
              <TextField
                fullWidth
                label="الى"
                margin="dense"
                id="wednesdayClose"
                name="wednesdayClose"
                onChange={(e) => handle(e)}
                value={data.wednesdayClose}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الخميس</label>
              <TextField
                fullWidth
                label=" من"
                margin="dense"
                id="thursdayOpen"
                name="thursdayOpen"
                onChange={(e) => handle(e)}
                value={data.thursdayOpen}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الخميس</label>
              <TextField
                fullWidth
                label="الى"
                margin="dense"
                id="thursdayClose"
                name="thursdayClose"
                onChange={(e) => handle(e)}
                value={data.thursdayClose}
                type="text"
                variant="outlined"
              />


            </Grid>


            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الجمعة</label>
              <TextField
                fullWidth
                label=" من"
                margin="dense"
                id="fridayOpen"
                name="fridayOpen"
                onChange={(e) => handle(e)}
                value={data.fridayOpen}
                type="text"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <label>الجمعة</label>
              <TextField
                fullWidth
                label="الى"
                margin="dense"
                id="fridayClose"
                name="fridayClose"
                onChange={(e) => handle(e)}
                value={data.fridayClose}
                type="text"
                variant="outlined"
              />
            </Grid>

          </Grid>

        </CardContent>
        <Divider />
        <CardActions>

          <button className='btn btn-primary px-5 m-4' >
            {loader ?
              <div className={"item"}>
                <Loader type="bubble-loop" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={60} />
              </div> :
              <> تحديث البيانات</>
            }

          </button>


        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
