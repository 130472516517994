import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios';
import swal from 'sweetalert';

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [shows, setShows] = useState(false);
  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [auth, setAuth] = useState({});
  const [posts, setPosts] = useState([]);
  const [userLeng, setUserLeng] = useState();
  const [cate, setCate] = useState([]);
  const [users, setUsers] = useState([]);
  const [len, setLen] = useState();
  const [profile, setProfile] = useState([]);
  const [messages, setMessages] = useState([]);
  const [counter, setCounter] = useState([]);
  const [visit, setVisit] = useState([]);

  const BASE_URL = process.env.REACT_APP_API_URL;
  const PRODUCT_URL = `${BASE_URL}/products`;
  const PRODUCT_URL1 = `${BASE_URL}/products`;
  const CATEGORY_URL = `${BASE_URL}/category`;
  const PROFILE_URL = `${BASE_URL}/profile`;
  const USER_URL = `${BASE_URL}/users`;
  const MESSAGE_URL = `${BASE_URL}/messages`;
  const VISITOR_URL = `${BASE_URL}/visitors`;
  const VISIT_URL = `${BASE_URL}/visit`;
  

  const userLoggedOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("isLoggedIn");
    window.location.replace('/sign-in');
  }

  useEffect(() => {
    axios.get(CATEGORY_URL, {
      headers: {
        'Authorization': ` Bearer ${passedToken}`
      }
    }).then((res) => {

      setCate(res.data);
      setLen(res.data.length);


    }).catch((err) => {
      console.log(err);
    })
  }, [])

  useEffect(() => {
    axios.get(PROFILE_URL, {
      headers: {
        'Authorization': ` Bearer ${passedToken}`
      }
    }).then((res) => {

      setProfile(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }, [])


  useEffect(() => {
    axios.get(USER_URL).then((res) => {
      setUsers(res.data);
      setUserLeng(res.data.length);

    }).catch((err) => {
      console.log(err);
    })
  }, [])


  //Get data from api
  const [productLength, setProductLength] = useState('')
  useEffect(() => {
    axios.get(PRODUCT_URL, {
      headers: {
        'Authorization': ` Bearer ${passedToken}`
      }
    }).then(res => {
      setPosts(res.data.data);
      setProductLength(res.data.total)

    }).catch(err => {
      console.log(err)
    })
  }, [])

  // Get users messages
  useEffect(() => {
    axios.get(MESSAGE_URL, {
      headers: {
        'Authorization': ` Bearer ${passedToken}`
      }
    }).then(res => {
      setMessages(res.data);


    }).catch(err => {
      console.log(err)
    })
  }, []);

  // Get users visitors count
  useEffect(() => {
    axios.get(VISITOR_URL, {
      headers: {
        'Authorization': ` Bearer ${passedToken}`
      }
    }).then(res => {
      setCounter(res.data);
      console.log();

    }).catch(err => {
      console.log(err)
    })
  }, []);

    // Get users visitors per day
    useEffect(() => {
      axios.get(VISIT_URL, {
        headers: {
          'Authorization': ` Bearer ${passedToken}`
        }
      }).then(res => {
        setVisit(res.data);
        console.log();
  
      }).catch(err => {
        console.log(err)
      })
    }, []);


  const menuItems = [...new Set(posts.map((product) => product.category_name))];

  const numberOfCategories = len;

  //Delete Item
  var accessTokenObj = (localStorage.getItem("token"));
  const passedToken = accessTokenObj;
  const deleteItem = async (e, id) => {
    const del = e.currentTarget;
    swal({
      title: "حذف منتج",
      text: "هل أنت متأكد من القيام بهذه العملية؟",
      icon: "warning",
      // buttons: true,
      buttons: ["إلغاء", "تأكيد"],
      dangerMode: true,

    })
      .then(async (willDelete) => {
        if (willDelete) {
          swal("تم حذف المنتج ", {
            icon: "success"
          });
          del.innerText = "جار الحذف...";
          await axios.delete(`${PRODUCT_URL1}/${id}`,
            {
              headers: {
                'Authorization': ` Bearer ${passedToken}`
              }
            }
          ).then(res => {
            if (res.status === 200) {
              del.closest("tr").remove();
              window.location.replace('/users')
            }

          }).catch(err => {
            console.log(err);
          })
        }
      });
  }

  //Delete Category
  const deleteCategory = async (e, id) => {
    const del = e.currentTarget;
    swal({
      title: "حذف صنف",
      text: "هل أنت متأكد من القيام بهذه العملية؟",
      icon: "warning",
      // buttons: true,
      buttons: ["إلغاء", "تأكيد"],
      dangerMode: true,

    }).then(async (willDelete) => {
      if (willDelete) {
        swal("تم حذف الصنف بنجاح", {
          icon: "success"
        });

        del.innerText = "جار الحذف...";
        await axios.delete(`${CATEGORY_URL}/${id}`,
          {
            headers: {
              'Authorization': ` Bearer ${passedToken}`
            }
          }
        ).then(res => {
          if (res.status === 200) {
            del.closest("tr").remove();
            window.location.replace('/category')
          }
          

        });
      }
    }).catch(err => {
      console.log(err);
    })
  }

  //Category Filter 
  const [item, setItem] = useState(posts);
  const arMenuItems = [...new Set(posts.map((product) => product.category_ar_name))];

  const arFilterItem = (curcat) => {
    const newItem = posts.filter((newVal) => {
      return newVal.category_ar_name === curcat;
    });
    setItem(newItem);
  }

  const contextValue = {
    auth,
    setAuth,
    posts,
    setPosts,
    deleteItem,
    numberOfCategories,
    userLeng,
    cate,
    handleShow,
    handleClose,
    show,
    userLoggedOut,
    users,
    deleteCategory,
    handleShows,
    handleCloses,
    shows,
    profile,
    menuItems,
    item,
    arMenuItems,
    arFilterItem,
    setItem,
    messages,
    productLength,
    counter,
    visit
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};