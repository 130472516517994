import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider
} from '@material-ui/core';

import { options } from './chart';
import { useContext } from 'react';
import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(() => ({

  
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
  const { className, ...rest } = props;
  const {visit} = useContext(ShopContext);
  const data = {
  
    labels: visit.map((item) => item.date),
    datasets: [
      {
        label: 'عدد الزوار',
        backgroundColor: palette.primary.main,
        data: visit.map((item) => item.count),
      },
      // {
      //   label: 'Last year',
      //   backgroundColor: palette.neutral,
      //   data: visit.map((item) => item.count),
      // }
    ]
  };

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
    
        title="أخر زيارات الموقع"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar 
            data={data}
            options={options} 
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        
      </CardActions>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
