import React, {useRef, useState, useEffect, useContext} from 'react'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
 
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';

// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';
// import UseAuth from 'hooks/useAuth';

import { ShopContext } from 'context/shop-context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(https://images.unsplash.com/photo-1566888596782-c7f41cc184c5?ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;
  console.log(history);
  const classes = useStyles();

  // const handleBack = () => {
  //   history.goBack();
  // };

  // const handleSignIn = event => {
  //   event.preventDefault();
  //   history.push('/');
  // };

    const BASE_URL = process.env.REACT_APP_API_URL;
    const LOGIN_URL = `${BASE_URL}/login`;
    
    const {auth, setAuth} = useContext(ShopContext);
    const userRef = useRef();
  
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [er, setEr] = useState(false);
    console.log(success);
  
    useEffect(()=>{
      userRef.current.focus();
    },[])
  
    useEffect(()=>{
      setErrMsg('');
    },[email, pwd])
  
    const handleSubmit = async (e) =>{
      e.preventDefault();
      try{
        const res = await axios.post(LOGIN_URL,
          JSON.stringify({email: email, password: pwd}),
        
          {
            headers:{'Content-Type':'application/json'},
           
          }
           );

           const accessToken = res.data.token;
           const roles = res?.data?.roles;
           const name = res.data.user.name;
           const store = res.data.user.storeName;
           const user_id = res.data.user.id;
  
           setAuth({email, pwd, roles, accessToken, name});
           window.localStorage.setItem("token", accessToken);
           window.localStorage.setItem("name", name);
           window.localStorage.setItem("email", email);
           window.localStorage.setItem("store", store);
           window.localStorage.setItem("user_id", user_id);
           window.localStorage.setItem("isLoggedIn", true);
           
           console.log('Logged In User', name);
      
       
        setEmail('')
        setPwd('')
        setSuccess(true);
         console.log('This Is Roles',roles)
         window.location.replace('/')
      } catch(err){
        console.log(err.response.status)
       if(!err.response.status){
        console.log(err)
        setErrMsg('عذراً حدث خطا الرجاء المحاولة مرة أخرى');
        setEr(true);
       }
       else if(err.response.status === 400 ){
        setErrMsg('Missing Email or Password');
        setEr(true);
       }
       else if(err.response.status === 401){
        setErrMsg('البريد الالكتروني او كلمة المرور خطا');
        setEr(true);
       }
       else{
        setErrMsg('عذرا حدث خطا الرجاء المحاولة مرة أخرى');
        setEr(true);
       }
       err.current.focus();
      }
    }

console.log('name', auth.name);


  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {/* Hella narwhal Cosby sweater McSweeney's, salvia kitsch before
                they sold out High Life. */}
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant="body1"
                >
                  {/* Takamaru Ayako */}
                </Typography>
                <Typography
                  className={classes.bio}
                  variant="body2"
                >
                  {/* Manager at inVision */}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            {/* <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div> */}
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSubmit}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                 تسجيل الدخول
                </Typography>
                {/* <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Sign in with social media
                </Typography> */}
                {/* <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      color="primary"
                      onClick={handleSignIn}
                      size="large"
                      variant="contained"
                    >
                      <FacebookIcon className={classes.socialIcon} />
                      Login with Facebook
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleSignIn}
                      size="large"
                      variant="contained"
                    >
                      <GoogleIcon className={classes.socialIcon} />
                      Login with Google
                    </Button>
                  </Grid>
                </Grid> */}
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  <div className='login-error text-danger'>
                  { er ?
                  <h4>  {errMsg}</h4>
                  :<></> 
                  } 
                  </div>

                  ادخل البريد الإلكتروني و كلمة المرور
                </Typography>
                <TextField
                  className={classes.textField}
                  // error={hasError('email')}
                  fullWidth
                  // helperText={
                  //   hasError('email') ? formState.errors.email[0] : null
                  // }
                  label="البريد الإلكتروني"
                  name="email"
                  id="email"
                  ref={userRef} 
                  autoComplete="off" 
                  onChange={(e)=> setEmail(e.target.value)}
                  value={email}
                  type="text"
                  variant="outlined"
                  required
                />
                <TextField
                  className={classes.textField}
                  // error={hasError('password')}
                  fullWidth
                  // helperText={
                  //   hasError('password') ? formState.errors.password[0] : null
                  // }
                  label="كلمة المرور"
                  type="password"
                  id="password"
                  onChange={(e)=> setPwd(e.target.value)}
                  value={pwd}
                  variant="outlined"
                  required
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  // disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                                    تسجيل الدخول
                </Button>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don't have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/sign-up"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
